import React from 'react';
import { PageProps, graphql, Link } from 'gatsby';
import GlobalStyle from '@/styles/GlobalStyles';


const Clinics = ({ data }) => {
  console.log(data)
  return <main>
    <GlobalStyle />
    <div>
      {data.directus.clinic.map((clinic) => <div style={{ display: 'block', padding: 24 }}>
        <strong>{clinic.name}</strong><br />
        <small>{clinic.description}</small><br />
        <small><em>{clinic.clinic_id}</em></small>
        <div style={{ color: 'teal', fontWeight: 'bold', marginTop: 12 }}>
          <a href={'/' + clinic.clinic_id + '/team'}>Team</a>{" | "}
          <a href={'/' + clinic.clinic_id + '/contact'}>Contact</a>{" | "}
          <a href={'/' + clinic.clinic_id + '/videos'}>Videos</a>
        </div>
      </div>)}
    </div>
  </main>

}
export default Clinics;


export const query = graphql`
  query Clinics {
    directus {
      clinic(filter: { connected: { _eq: true } }) {
        clinic_id
        name
        description
      }
    }
  }
`
